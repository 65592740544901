
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.wrapper {
    font-size: 0.75em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &:not(:first-of-type) {
        padding-top: 0;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        background: #9AA3B4;
        border-radius: 2px;
        color: #FFFFFF;
        margin-right: 4px;

        i {
            font-size: 8px;
        }

        &.more {
            transform: rotate(90deg);
            background: #1BD12D;
        }

        &.less {
            transform: rotate(-90deg);
            background: #FE2615;
        }
    }
}

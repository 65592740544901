
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.jumper {
    height: auto !important;
    font-size: 12px !important;
    text-align: center;
    font-weight: normal;
    line-height: 32px !important;
    color: #9AA3B4;
}

.flex {
    align-items: center;
    justify-content: center;
    
    height: 100%;
    color: #606266;
}

.news-list {
    &__item {
        @apply py-2 px-4 mb-4;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0 2px 5px rgba(22, 34, 57, 0.08),
                    inset 1px 0 0 #FE2615;
        border-radius: 4px;

        &-inner {
            display: flex;
            align-items: center;

            @media screen and (max-width: 768px) {
                display: block;
            }
        }

        &-date {
            color: #9AA3B4;
        }

        &-title {
            font-weight: 400;
            @apply mr-4 ml-4;

            @media screen and (max-width: 768px) {
                @apply mt-3 mr-0 ml-0;
            }
        }

        &-dropdown {
            color: #9AA3B4;
            margin-left: auto;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
}


          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

@mixin shadow {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
}

.leads-tab {
    ::v-deep.card-header {
        background: #ecf6ff;
        @include shadow;

        .card-header-title {
            padding: 10px;
            justify-content: center;
            color: #1a55af;
        }
    }
}

.approve-tab {
    ::v-deep.card-header {
        background: #f6f4ff;
        @include shadow;

        .card-header-title {
            color: #5843be;
        }
    }
}

.commission-tab {
    ::v-deep.card-header {
        background: #fffbf0;
        @include shadow;

        .card-header-title {
            color: #427a0a;
        }
    }
}

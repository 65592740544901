:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

:export{
  mainFont:"Rubik";
  bodyFonts:"Rubik",BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
  monospaced:"Fira Mono"
}

:export{
  sizeNormal:.875rem
}

:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

.f-col{
  padding:.5rem
}

@media (min-width: 769px){
  ::v-deep .dashboard-filters{
    width:75% !important;
    justify-content:flex-end !important
  }
}

@media (min-width: 1280px){
  ::v-deep .dashboard-filters{
    display:flex !important;
    width:80% !important
  }
}

@media (min-width: 1441px){
  ::v-deep .dashboard-filters{
    display:block !important
  }
}

@media (min-width: 769px){
  ::v-deep .dashboard-filters .filters{
    display:inline-block
  }
}

@media (min-width: 1280px){
  ::v-deep .dashboard-filters .filters{
    display:flex
  }
}
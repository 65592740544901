
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.f-datepicker {
    min-width: 150px;
    &__popper {
        @media screen and (max-width: 1024px) {
            position: fixed !important;
            width: calc(100vw - 40px);
            max-width: 400px;
            top: 25% !important;
            left: 50% !important;
            bottom: auto !important;
            right: auto !important;
            transform: translate3d(-50%, -25%, 0);
            white-space: normal;
            overflow-y: auto;
        }
        
        @media screen and (max-width: 921px) {
            max-width: 400px;
        }
        
        @media screen and (max-width: 411px) {
            max-width: 360px;
        }
        
        .popper__arrow {
            display: none;
        }
        
        &[x-placement^=top] {
            margin-bottom: 4px;
        }
        
        &[x-placement^=bottom] {
            margin-top: 4px;
        }
        
        .el-date-range-picker__header {
            background-color: #9AA3B4;
            border-radius: 4px 4px 0 0;
            padding: 0 10px 0 10px;
            color: white;
        }
        
        .el-date-table td.in-range div {
            background-color: white;
        }
        
        .el-date-table td.end-date span, .el-date-table td.start-date span{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #9AA3B4;
            font-weight: 400;
            border-radius: 4px;
            padding: 14px;
        }
        
        .el-date-table td.today span {
            color: #606266;
            font-weight: 400;
        }
    }
}


          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.card {
    height: 100%;
    padding: 2.5em 1em 1em;
    background: #FFFFFF;
    box-shadow: 0 10px 20px rgba(22, 34, 57, 0.05),
                inset 0 1px 0 #FE2615;
    border-radius: 4px;
    overflow: auto;

    .icon {
        margin-bottom: 1.5em;
    }

    &__inner {
        @media screen and (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0.5em;
        }
    }

    .value {
        font-size: 1.75em;
        font-weight: 600;
        margin-bottom: 0.5em;

        @media screen and (max-width: 768px) {
            margin-bottom: 0;
            margin-right: 0.5em;
        }
    }

    .difference {
        margin-bottom: 0.5em;

        @media screen and (max-width: 768px) {
            margin-bottom: 0;
        }
    }

    .label {
        font-size: 0.75em;
        color: #9AA3B4;
    }
}


          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

$types: (
        primary: #5921A0,
        success: #1BD12D,
        info: #9AA3B4,
        warning: #E2C21A,
        danger: #FE2615
);

.el-main {
    @apply max-w-min;

    div {
        @apply w-56;
    }
}

.el-button {
    @each $type, $color in $types {
        $color-light: transparentize($color, $alpha);

        &.el-button--#{$type} {
            color: $color;
            background: $color-light;
            border-color: $color;
        }

        &[datatype="#{$type}"] {
            &:hover, &:focus {
                color: $color;
                border-color: $color;
            }
        }
    }
}

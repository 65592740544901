
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
    .card {
        .card-header {
            .card-header-title {
                padding: 20px;
            }
        }
        
        .card-content {
            padding: 0;
            background: linear-gradient(180deg, transparent, #ECEFFE 40%);
        }
    }
    
    .custom-header-padding {
        padding: 0 20px 0;
        margin-bottom: 0 !important;
    }
    
    .no-padding {
        padding-bottom: 0 !important;
        
        .tab-content {
            padding: 0 !important;
        }
    }
    
    .clearTable {
        display: inline-table;
        
        td {
            font-size: inherit;
            
            background: transparent !important;
            border-bottom: none !important;
            
            &:first-child {
                width: 43px;
                text-align: right;
            }
        }
    }

    .top-card {
        &-title {
            color: #7D8DA0;
        }

        &-count {
            color: #97ADFF;
        }
    }

    .table-wrapper {
        /*background: #F6F7FF;*/
        overflow-x: auto !important;

        tbody {
            background: transparent;
        }

        thead {
            tr {
                th {
                    background: transparent;
                }
            }
        }

        tr {
            &:first-of-type {
                td:first-child {
                    border-top-left-radius: 0;
                }
            }

            &:last-child {
                td:first-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: $radius-large;
                }

                td:last-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: $radius-large;
                }
            }

            &.is-empty {
                td {
                    border-bottom: 1px solid white;

                    &:after {
                        background-color: white;
                    }

                    &:first-child {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }

            th {
                position: static!important;

                &:first-child {
                    background: #ECEFFE;
                }
            }

            td {
                border-bottom: 1px solid white;

                &:after {
                    background-color: white;
                }

                &:first-child {
                    background: #ECEFFE;
                }
            }
        }
    }
}


          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.card {
    &__buttons {
        @apply flex;

        @media screen and (max-width: 350px) {
            @apply flex-wrap;
        }

        &-item  {
            @apply mb-4;

            @media screen and (max-width: 768px) {
                width: calc(50% - 0.5rem);
                /*margin-right: 1rem;*/

                /*&:nth-child(even) {*/
                /*    margin-right: 0;*/
                /*}*/
            }

            @media screen and (max-width: 350px) {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}


          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.chart {
    min-height: 500px;
    border-radius: 4px;
}

.chart-tooltip {
    &__list {
        margin-top: 10px;
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__item-value {
        margin-left: auto;
        padding-left: 1em;
    }
}
